import React from "react";

import Layout from "../components/Layout/Layout";
import Home from "../containers/Home";
import SEO from "../components/seo";

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO />
    <Home location={location.href} />
  </Layout>
);

export default NotFoundPage;
